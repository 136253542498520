<template>
  <div class="body">
    <p style="color: green; font-size: 30px">欢迎回来，亲爱的{{ admin }}</p>
    <div class="heart"></div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      admin: '管理员'
    }
  }
}
</script>
<style lang="scss" scoped>
.body {
  height: 100%;
  background-color: #1f1f1f;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.heart {
  background-color: crimson;
  display: inline-block;
  width: 100px;
  height: 100px;
  position: relative;
  top: 0;
  transform: rotate(-45deg);
  position: absolute;
  left: 45%;
  top: 45%;
  animation: heartbeat 1.25s infinite;
  margin-left: 150px;
}

.heart::before,
.heart::after {
  content: "";
  background-color: crimson;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  animation: pulsecolor 1.25s infinite;
}

.heart::before {
  top: -50px;
  left: 0;
}

.heart::after {
  top: 0;
  left: 50px;
}

@keyframes heartbeat {
  0% {
    transform: scale(1) rotate(-45deg);
  }
  10% {
    transform: scale(1.25) rotate(-45deg);
    background-color: #ef395e;
  }
  100% {
    transform: scale(1) rotate(-45deg);
  }
}

@keyframes pulsecolor {
  10% {
    background-color: #ef395e;
  }
}
</style>
